import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import { isDevMode } from '@/utils/lib.js'
import { PermissionLevel } from '@/config/permissions.js'

Vue.use(VueRouter)

// ref: https://juejin.cn/post/7048568562104926244
const originalReplace = VueRouter.prototype.replace
const originalPush = VueRouter.prototype.push
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    name: 'Entry',
    component: () => import('@/views/Home.vue'),
    redirect: 'dashboard',
    children: [
      // 勤務派遣
      {
        path: 'dashboard/:seconds?',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      // 歷史事件
      {
        path: 'history',
        name: 'History',
        component: () => import('@/views/History.vue'),
        // redirect: 'lpr',
        children: [
          {
            path: 'lpr',
            name: 'Lpr',
            component: () => import('@/components/History/HistoryLpr.vue'),
          },
          {
            path: 'fr',
            name: 'Fr',
            component: () => import('@/components/History/HistoryFr.vue'),
          },
          {
            path: 'or',
            name: 'Or',
            component: () => import('@/components/History/HistoryOr.vue'),
          },
          {
            path: 'urgent',
            name: 'Urgent',
            component: () => import('@/components/History/HistoryUrgent.vue'),
          },
          {
            path: 'video',
            name: 'Video',
            component: () => import('@/components/History/HistoryVideo.vue'),
          },
        ]
      },
      // 比對資料庫-lpr
      {
        path: 'lpr_recognition/:pathMatch(.*)*', // 車辨: lpr
        name: 'LPR',
        component: () => import('@/views/LprRecognition.vue'),
      },
      // 比對資料庫-fr
      {
        path: 'fr_recognition', // 人辨: fr
        name: 'FR',
        component: () => import('@/views/FrRecognition.vue'),
      },
      // 比對資料庫-or
      // {
      //   path: 'or_recognition', // 物辨: or
      //   name: 'OR',
      //   component: () => import('@/views/OrRecognition.vue'),
      // },
      // 帳號與授權
      {
        path: 'account-management',
        name: 'AccountManagement',
        component: () => import('@/views/AccountManagement.vue'),
        // redirect: 'user',
        children: [
          {
            path: 'user',
            name: 'User',
            component: () => import('@/components/AccountManagement/User.vue'),
          },
          {
            path: 'device',
            name: 'Device',
            component: () => import('@/components/AccountManagement/Device.vue'),
          },
          {
            path: 'group',
            name: 'Group',
            component: () => import('@/components/AccountManagement/Group2.vue'),
          },
          {
            path: 'role',
            name: 'Role',
            component: () => import('@/components/AccountManagement/Role.vue'),
          },
        ]
      },
      // 系統與服務
      {
        path: 'system-manage',
        name: 'SystemManage',
        component: () => import('@/views/SystemManage.vue'),
        children: [
          {
            path: 'systemSetting',
            name: 'SystemSetting',
            component: () => import('@/components/SystemManage/SystemSetting.vue'),
          },
          {
            path: 'systemDevice',
            name: 'SystemDevice',
            component: () => import('@/components/SystemManage/SystemDevice.vue'),
          },
          {
            path: 'systemAibox',
            name: 'SystemAibox',
            component: () => import('@/components/SystemManage/SystemAibox.vue'),
          },
          {
            path: 'systemServer',
            name: 'SystemServer',
            component: () => import('@/components/SystemManage/SystemServer.vue'),
          },
          {
            path: 'systemCertificate',
            name: 'SystemCertificate',
            component: () => import('@/components/SystemManage/SystemCertificate.vue'),
          },
        ]
      },
      {
        path: 'log',
        name: 'Log',
        component: () => import('@/views/Log.vue'),
        children: [
          {
            path: 'accessLog',
            name: 'AccessLog',
            component: () => import('@/components/Log/AccessLog.vue'),
          },
          {
            path: 'deviceLog',
            name: 'DeviceLog',
            component: () => import('@/components/Log/DeviceLog.vue'),
          },
          // {
          //   path: 'resourceLog',
          //   name: 'ResourceLog',
          //   component: () => import('@/components/Log/ResourceLog.vue'),
          // },
        ]
      },
    ]
  },
  // {
  //   path: '/time-lapse', // 縮時攝影
  //   name: 'TimeLapse',
  //   component: () => import('@/views/TimeLapse.vue')
  // },
  {
    path: '/video-test',
    name: 'VideoTest',
    component: () => import('@/views/VideoTestView.vue'),
  },
  {
    path: '/construction',
    name: 'Construction',
    component: () => import('@/views/ConstructionView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/npa_login',
    name: 'NpaLogin',
    component: () => import('@/views/NpaLogin.vue')
  },
  {
    path: '*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes // 需要有最完整的路徑, 再用 beforeEach 做轉發
})

export const routeGo = (path) => {
  // console.log(`[router.routeGo] path:`, path)
  if (!router.history.current.path.includes(path)) {
    router.push({ path })
  }
}

export const dashboardPermission = (permissionV2) => {
  const { dashboard } = permissionV2
  return dashboard > PermissionLevel.L0
}

export const historyPermission = (permissionV2) => {
  const lpr = permissionV2.lprEventAccess > PermissionLevel.L0 || permissionV2.lprEventAdvancedAccess > PermissionLevel.L0
  const fr = permissionV2.frEventAccess > PermissionLevel.L0
  const or = permissionV2.orEventAccess > PermissionLevel.L0
  const urgent = true // emergencyEventAccess // TODO
  const video = true // voiceCallEventAccess // TODO

  const obj = { lpr, fr, or, urgent, video }
  const def = Object.keys(obj).filter((key) => obj[key])[0]
  const ret = lpr || fr || or || urgent || video

  return { ret, ...obj, def }
}

export const lprPermission = (permissionV2) => {
  const {
    lprSummary,
    lprSubscription,
    lprMissionLv,
    lprMission,
    lprInfoLv,
    lprInfo,
    lprAnnouncement,
    lprReport,
    // lprGroupInfoLv,
    // lprGroupInfo
  } = permissionV2
  const lpr =
    lprSummary > PermissionLevel.L0 ||
    lprSubscription > PermissionLevel.L0 ||
    lprMissionLv > PermissionLevel.L0 ||
    lprMission > PermissionLevel.L0 ||
    lprInfoLv > PermissionLevel.L0 ||
    lprInfo > PermissionLevel.L0 ||
    lprAnnouncement > PermissionLevel.L0 ||
    lprReport > PermissionLevel.L0 // ||
  // lprGroupInfoLv ||
  // lprGroupInfo
  return lpr
}
export const frPermission = (permissionV2) => {
  const {
    frInfoLv,
    // frInfo
  } = permissionV2
  const fr = frInfoLv > PermissionLevel.L0 // || frInfo > PermissionLevel.L0
  return fr
}
// export const orPermission = (permissionV2) => {
//   const { orInfoLv, orInfo } = permissionV2
//   const or = orInfoLv > PermissionLevel.L0 || orInfo > PermissionLevel.L0
//   return or
// }
export const recognitionPermission = (permissionV2) => {
  const lpr = lprPermission(permissionV2)
  const fr = frPermission(permissionV2)
  // const or = orPermission(permissionV2)

  const obj = { lpr, fr /*, or*/ }
  const def = Object.keys(obj).filter((key) => obj[key])[0]
  const ret = lpr || fr //|| or

  return { ret, lpr, fr /* , or*,*/, def }
}

export const accountPermission = (permissionV2) => {
  const user = permissionV2.account > PermissionLevel.L0
  const device = permissionV2.device > PermissionLevel.L0
  const group = permissionV2.group > PermissionLevel.L0
  const role = permissionV2.roleManagement > PermissionLevel.L0

  const obj = { user, device, group, role }
  const def = Object.keys(obj).filter((key) => obj[key])[0]
  const ret = user || device || group || role

  return { ret, ...obj, def }
}

export const systemPermission = (permissionV2) => {
  const system = permissionV2.system > PermissionLevel.L0
  const systemDevice = isDevMode() && permissionV2.systemDevice > PermissionLevel.L0 // TODO
  const systemAibox = permissionV2.systemAibox > PermissionLevel.L0
  const systemServer = permissionV2.systemServer > PermissionLevel.L0
  const systemCertificate = permissionV2.systemCertificate > PermissionLevel.L0

  const obj = {
    system,
    systemDevice,
    systemAibox,
    systemServer,
    systemCertificate
  }
  const def = Object.keys(obj).filter((key) => obj[key])[0]
  const ret =
    system || systemDevice || systemAibox || systemServer || systemCertificate

  // console.log(`[systemPermission] def, obj:`, def, obj)
  return { ret, ...obj, def }
}

export const logPermission = (permissionV2) => {
  const access = permissionV2.audit >= PermissionLevel.L0
  const device = store.state.staff ? true : false // TBD permissionV2.xxx
  // const resource = permissionV2.audit >= PermissionLevel.L0

  const obj = {
    access,
    device,
    // resource,
  }
  const def = Object.keys(obj).filter((key) => obj[key])[0]
  const ret = access || device // || resource

  return { ret, ...obj, def }
}

export const timeLapsePermission = (permissionV2) => {
  const { videoManagement } = permissionV2

  const ret = videoManagement > PermissionLevel.L0
  return ret
}

router.beforeEach((to, from, next) => {
  // console.log(`[Router]`, from.path, 'to', to.path)
  const isLogin = localStorage.getItem('refreshToken')
  const ssoLoginPage = routes.find(
    (router) => router.path === '/npa_login'
  ).path

  if (to.path === ssoLoginPage) {
    store.commit('updateSsoLogin', true)
  }

  let defPage = store.state.ssoLogin ? ssoLoginPage : '/login'
  console.log(`[Router] to.path, defPage:`, to.path, defPage)

  if (isLogin && to.path !== defPage) {
    // lprDashboard > 0，才有權限進入dashboard頁面
    // lprEventAccess > 0，才有權限進入history頁面; 1: 一般查詢, 2: 進階查詢
    // lprSummary > 0，才有權限進入lpr_recognition頁面

    const dashboard = dashboardPermission(store.state.permissionV2)
    const history = historyPermission(store.state.permissionV2)
    const recognition = recognitionPermission(store.state.permissionV2)
    const account = accountPermission(store.state.permissionV2)
    const system = systemPermission(store.state.permissionV2)
    const log = logPermission(store.state.permissionV2)
    // const timelapse = timeLapsePermission(store.state.permissionV2)

    // console.log(`[Router] dashboard: ${dashboard}, history: ${history}, recognition: ${recognition}, account: ${account}, system: ${system}`)

    if (to.path.includes('/dashboard') && dashboard) next()
    else if (to.path.includes('/history') && history.ret) {
      if (to.path.includes('lpr') && history.lpr) next()
      else if (to.path.includes('fr') && history.fr) next()
      else if (to.path.includes('or') && history.or) next()
      else if (to.path.includes('urgent') && history.urgent) next()
      else if (to.path.includes('video') && history.video) next()
      else next(`/history/${history.def}`)
    } else if (to.path.includes('_recognition') && recognition.ret) {
      if (to.path.startsWith('/lpr_recognition') && recognition.lpr) next()
      else if (to.path === '/fr_recognition' && recognition.fr) next()
      // else if (to.path === '/or_recognition' && recognition.or) next()
      else next(`${recognition.def}_recognition`)
    } else if (to.path.includes('/account-management') && account.ret) {
      if (to.path.includes('user') && account.user) next()
      else if (to.path.includes('device') && account.device) next()
      else if (to.path.includes('group') && account.group) next()
      else if (to.path.includes('role') && account.role) next()
      else next(`/account-management/${account.def}`)
    } else if (to.path.includes('/system-manage') && system.ret) {
      if (to.path.includes('systemSetting') && system.system) next()
      else if (to.path.includes('systemDevice') && system.systemDevice) next()
      else if (to.path.includes('systemAibox') && system.systemAibox) next()
      else if (to.path.includes('systemServer') && system.systemServer) next()
      else if (to.path.includes('systemCertificate') && system.systemCertificate) next()
      else next(`/system-manage/${system.def}`)
    } else if (to.path.includes('/log') && log.ret) {
      if (to.path.includes('accessLog') && log.access) next()
      else if (to.path.includes('deviceLog') && log.device) next()
      // else if (to.path.includes('resourceLog') && log.resource) next()
      else next(`/log/${log.def}`)
    }
    // else if (to.path === '/time-lapse' && timelapse) next()
    else if (to.path === '/video-test') {
      next()
    } else if (to.path === '/construction') {
      next()
    } else {
      console.log(`[Router] else log:`, log)
      // 手打URL，或是沒有權限的頁面
      if (dashboard) next('/dashboard')
      else if (history.ret) {
        if (history.lpr) next('/history/lpr')
        else if (history.fr) next('/history/fr')
        else if (history.or) next('/history/or')
        else if (history.urgent) next('/history/urgent')
        else if (history.video) next('/history/video')
        else next(`/history/${history.def}`)
      } else if (recognition.ret) {
        if (recognition.lpr) next('/lpr_recognition')
        else if (recognition.fr) next('/fr_recognition')
        // else if (recognition.or) next('/or_recognition')
        else next(defPage)
      } else if (account.ret) {
        if (account.user) next('/account-management/user')
        else if (account.device) next('/account-management/device')
        else if (account.group) next('/account-management/group')
        else if (account.role) next('/account-management/role')
        else next(`/account-management/${account.def}`)
      } else if (system.ret) {
        if (system.system) next('/system-manage/systemSetting')
        else if (system.systemDevice) next('/system-manage/systemDevice')
        else if (system.systemAibox) next('/system-manage/systemAibox')
        else if (system.systemServer) next('/system-manage/systemServer')
        else if (system.systemCertificate) next('/system-manage/systemCertificate')
        else next(`/system-manage/${system.def}`)
      } else if (log.ret) {
        if (log.access) next('/log/accessLog')
        else if (log.device) next('/log/deviceLog')
        // else if (log.resource) next('/log/resourceLog')
        else next(`/log/${log.def}`)
      }
      // else if (timelapse) next('/time-lapse')
      else next(defPage)
    }
    // 登入成功後，呼叫API取得共用的資料
    // store.dispatch('global/getDeviceModels')
  } else if (to.path.includes('/video-test')) {
    next()
  } else if (to.path.includes('/construction')) {
    next()
  } else {
    if (to.path !== defPage) next(defPage)
    else next()
  }
})

// console.log(`[router] router:`, router)

export default router
